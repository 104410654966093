export const fetchQueryPath = (path: string) => {
  const runtimeConfig = useRuntimeConfig()

  const config = {
    headers: {
      Authorization: `Bearer ${runtimeConfig.public.strapiAccessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }

  return $fetch(`${runtimeConfig.strapiPrivateUrl || runtimeConfig.public.strapiPublicUrl}/api/${path}`, config)
}
