export const queryFragmentSeo = {
  seo: {
    populate: {
      image: '*'
    }
  }
}

export const queryFragmentLink = {
  educationEntry: {
    fields: ['title', 'slug']
  },
  eventEntry: {
    fields: ['title', 'slug']
  },
  exhibitionEntry: {
    fields: ['title', 'slug']
  },
  journalEntry: {
    fields: ['title', 'slug']
  },
  pageEntry: {
    fields: ['title', 'slug']
  },
  projectEntry: {
    fields: ['title', 'slug']
  }
}

export const queryFragmentBlocksLinks = {
  links: {
    populate: queryFragmentLink
  },
}

export const queryFragmentBlocks = {
  blocks: {
    populate: {
      ...queryFragmentBlocksLinks,
      files: {
        populate: '*'
      },
      summaries: {
        populate: '*'
      },
      details: {
        populate: '*'
      },
      listItems: {
        populate: '*'
      },
      sponsors: {
        populate: {
          logo: {
            populate: '*'
          }
        }
      },
      downloads: {
        populate: '*'
      },
      groups: {
        populate: {
          items: {
            populate: {
              image: true
            }
          }
        }
      }
    }
  }
}

export const queryFragmentFirstRichTextBlock = {
  on: {
    'blocks.rich-text': {
      fields: ['richText'],
      filters: {
        richText: {
          $notNull: true
        }
      }
    }
  }
}
